import { Home, Search, X } from "lucide-react";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../redux-store/stores/store";
import DropedFilesViewer from "../droped-file-uploader/droped-file-viewer";
import User from "../user/user";
import logo from '../../../../assets/images/logo.png'
import icon from '../../../../assets/icons/icon.png'
import { Input } from "../../ui/input";
import { resetSelectedChat, setNewMessageStatus, updateQuestion } from "../../../../redux-store/reducers/chat-reducers";
import { ChatService } from "../../../../shared/services/chat/chat-service";
import { cn } from "../../../lib/utils";
import CommandInput from "../../../../shared/components/command-input/command-input";

const Navbar = () => {
    const companyInfo = {
        name: '',
        logo: <img className="h-[60px] hidden sm:block" src={logo} alt="logo" />,
        minilogo: <img className="h-[60px] w-[70px] sm:hidden" src={icon} alt="mini logo" />,
        path: '/home'
    };


    const defaultNav = [
        {
            title: 'Home',
            path: '/home',
            icon: <Home className="size-4" />,
            tooltip: '',
            component: '',
            children: []
        },
    ]

    const [open, setOpen] = useState(false);
    const dispatch = useDispatch<AppDispatch>()
    const chatModel = useSelector((state: RootState) => state.chat.chatModel); 
    const includeFiles = useSelector((state:RootState)=>state.chat.chatIncludeFiles)
    // const navigate = useNavigate()

    // const handleDialog = () => {
    //     setOpen(!open)
    // }

    // const { selectedFiles, selectAll, files } = useSelector((store: RootState) => store.dashboard)

    // const resetFileSelectionHandler = () => {
    //     dispatch(setSelectAll(false))
    // }

    // const selectAllHandler = () => {
    //     dispatch(setSelectAll(!selectAll))
    // }

    // const handleEditClick = () => {
    //     navigate('/browse/edit')
    // }


    // const handleDeleteClick = () => {

    // }
    const location = useLocation();
    const navigate = useNavigate();


    const [query, setQuery] = useState('');
    const chatServiceInstatnce = useMemo(() => new ChatService(), []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setQuery(value);

    };
    const handleKeyUp = (event: any) => {
        if (event.key === 'Enter') {
            sendMessage();
        }
    }

    const sendMessage = () => {
        const message = {
            id: null,
            Question: query,
            Categories: [chatModel],
            IncludeFiles: includeFiles
        }
        dispatch(resetSelectedChat())
        dispatch(setNewMessageStatus(true))
        dispatch(chatServiceInstatnce.SendMessage(message))
        dispatch(updateQuestion(message.Question))
        setQuery('')
        if (location.pathname.split('/')[0] !== 'home') {
            navigate('/home')
        }
    }

    const handleClear = () => {
        setQuery('');
    };

    return (
        <header className="fixed relative top-0 z-30 flex flex-col sm:flex-row md:justify-between space-y-2 sm:space-y-0 w-full sm:min-h-[120px] md:min-h-[72px] shadow-lg border-b bg-background  sm:border-0">
            <nav className=" flex-col lg:w-1/4 md:w-3/4 gap-6  sm:px-9 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
                <NavLink
                    to={companyInfo.path}
                    className="flex pl-6 items-center gap-2 text-lg font-semibold md:text-base h-full"
                >
                    {companyInfo.logo}
                    <span className="w-[250px]">{companyInfo.name}</span>
                </NavLink>
            </nav>
            <div className="hidden sm:flex w-full max-w-[40rem] justify-center px-2 md:p-0">
                <div className="flex items-center h-full w-full relative">
                    <div className="w-full py-1 border rounded-3xl bg-muted">
                        <CommandInput
                            value={query}
                            handleKeyUp={(value) => handleKeyUp(value)}
                            handleOnChange={(value) => handleChange(value)}
                            placeholder="Search Assets..."
                            setPrompt={(value): void => { setQuery(value) }} isDropdownAbove={false} />
                    </div>

                    <div className='flex flex-row items-center justify-end absolute right-2.5 space-x-3 pr-3'>
                        {query &&
                            (
                                <X onClick={handleClear}
                                    className="size-6 text-muted-foregroud" />
                            )}
                        <Search className="size-6 text-muted-foreground" />
                    </div>
                </div>
            </div>
            <div className="hidden sm:flex flex-row items-center  px-9 justify-end gap-4 md:ml-auto md:gap-2 lg:gap-4">
                <nav className="flex flex-row w-full space-x-2">
                    {defaultNav.map((element, index) => (
                        <div key={index}>
                            <NavLink
                                to={element.path}
                                className={cn("text-muted-foreground flex flex-row items-center transition-colors rounded-3xl border-1 px-6 py-1 min-h-[46px] border border-input bg-muted shadow-sm hover:bg-accent hover:text-accent-foreground", (isActive: any) => isActive ? "bg-muted" : "")}
                            >
                                {element.icon}
                                <span className="flex ml-2 text-sm">{element.title}</span>
                            </NavLink>
                        </div>
                    ))}
                </nav>
                <div className="flex flex-row w-fit">
                    {/* <Button variant="outline" className="rounded-3xl space-x-2" onClick={handleDialog}>
                        <CloudUpload className="size-4" />
                        <span> Upload Files</span>
                    </Button> */}
                    <DropedFilesViewer open={open} setOpen={setOpen} />
                </div>
                {/* <div className="flex flex-row w-10">
                    <Notification />
                </div> */}
                <div className="flex flex-row w-14">
                    <User />
                </div>
            </div>
            <div className="flex flex-row sm:hidden w-full px-2 space-x-2">

                <nav className=" ">
                    <NavLink
                        to={companyInfo.path}
                        className=""
                    >
                        {companyInfo.minilogo}
                    </NavLink>
                </nav>

                <div className="flex items-center h-full w-full relative">
                    <Input
                        type="text"
                        value={query}
                        onChange={handleChange}// to handle click before blur
                        placeholder="Search Assets"
                        className="w-full py-3 px-6 border rounded-3xl"
                        onKeyUp={(event: any) => handleKeyUp(event)}
                    />
                    <div className='flex flex-row items-center justify-end absolute right-2.5 space-x-3 pr-3'>
                        {query &&
                            (
                                <X onClick={handleClear}
                                    className="size-6 text-muted-foregroud" />
                            )}
                        <Search className="size-6 text-muted-foreground" />
                    </div>
                </div>
                <div className="flex flex-row w-14 mt-1">
                    <User />
                </div>
            </div>
        </header>
    )
};

export default Navbar
import React, { useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "../../../core/components/ui/carousel";
import { fetchCompanyList } from "../../../shared/services/manager";

const UserSettings = () => {
  const fetchCompany = fetchCompanyList();
  const companyDetails = fetchCompany.values().next().value;
  const { role } = companyDetails;

  const sections = [
    { link: "general", title: "General" },
    ...(role !== "1" ? [
      { link: "integrations", title: "Integration" },
      { link: "userManagement", title: "Users & Teams" },
      { link: "searchCockpit", title: "Search Cockpit" },
      { link: "CategoryConfig", title: "Category Config" },
    ] : []),
    { link: "APIkey", title: "API Keys" },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  // Handle Next button click
  const handleNext = () => {
    if (currentIndex < sections.length - 1) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      navigate(`/profile/${sections[newIndex].link}`);
    }
  };

  // Handle Previous button click
  const handlePrevious = () => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      setCurrentIndex(newIndex);
      navigate(`/profile/${sections[newIndex].link}`);
    }
  };

  return (
    <main className="flex flex-col h-full w-full max-w-7xl mx-auto space-y-4 pt-10 px-4">
      <div className="flex flex-col gap-4">
        <div className="grid w-full">
          <h1 className="text-3xl font-extrabold">Settings</h1>
        </div>

        {/* Carousel for mobile view */}
        <div className="block md:hidden w-full">
          <Carousel className="w-full max-w-sm">
            <CarouselContent
              className="flex transition-transform duration-300 ease-in-out"
              style={{
                transform: `translateX(-${currentIndex * 100}%)`, // Move based on index
              }}
            >
              {sections.map((nav, index) => (
                <CarouselItem key={index} className="w-full flex justify-center">
                  <div className="p-1">
                    <div className="flex items-center justify-center">
                      <NavLink
                        to={nav.link}
                        className={({ isActive }) =>
                          isActive
                            ? "font-semibold text-primary bg-slate-200 px-6 py-1 rounded-3xl"
                            : "px-6 py-1"
                        }
                      >
                        {nav.title}
                      </NavLink>
                    </div>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>

            {/* Previous button */}
            <CarouselPrevious
              onClick={() => {
                console.log("Previous button clicked");
                handlePrevious(); // Decrement index on click
              }}
              className={`absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full cursor-pointer ${
                currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={currentIndex === 0} // Prevent clicking when at the first item
            >
              &#9664; {/* Previous Arrow */}
            </CarouselPrevious>

            {/* Next button */}
            <CarouselNext
              onClick={() => {
                console.log("Next button clicked");
                handleNext(); // Increment index on click
              }}
              className={`absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full cursor-pointer ${
                currentIndex === sections.length - 1 ? 'hidden' : ''
              }`}
            >
              &#9654; {/* Next Arrow */}
            </CarouselNext>
          </Carousel>
        </div>

        {/* Navigation for larger screens */}
        <nav className="hidden md:flex w-full gap-2 items-center border-b-2 border-slate-200 pb-4">
          {sections.map((nav) => (
            <NavLink
              key={nav.link}
              to={nav.link}
              className={({ isActive }) =>
                isActive
                  ? "font-semibold text-primary bg-slate-200 px-6 py-1 rounded-3xl"
                  : "px-6 py-1"
              }
            >
              {nav.title}
            </NavLink>
          ))}
        </nav>
      </div>

      <div className="h-full overflow-auto">
        <Outlet />
      </div>
    </main>
  );
};

export default UserSettings;
